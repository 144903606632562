<template>
  <div>
    <!--  중복 처리 이슈-->
    <QnaReply v-if="noteVisible" :class="{ show: noteVisible }" @close="closeModal2" :qnaData="qnaData" />
    <div class="Mypage">
      <div class="subHeader base">
        <h2>{{ $t("mypage_myinfo") }}</h2>
        <div class="grid c2">
          <article class="left subDash card">
            <div class="profile">
              <a href="#" class="btnPhoto" @click="handleUpload">
                <img v-if="userInfo.userImagePath" :src="baseUrl + userInfo.userImagePath" alt />
                <img v-else src="@/assets/images/icon-male.png" alt />
                <!-- <p class="circle">
                  <i class="material-icons">edit</i>
                </p>-->
              </a>
              <div class="info">
                <em>{{ username }}</em>
                <em>{{ type == "2" ? "파트너" : "" }}</em>
              </div>
            </div>

            <!-- <ul class="dlList">
              <li>
                <i>홍길동</i>
              </li>
              <li>
                <i>won@gmail.com</i>
              </li>
              <li>
                <i class="point"><span>5000</span>&nbsp;P</i>
              </li>
            </ul>-->

            <ul class="menuList">
              <!-- <li @click="handleMenu('1')">
                <b>장바구니</b>
              </li>-->

              <li @click="handleMenu('1')">
                <span class="material-icons">person</span>
                <b>{{ $t("mypage_profile") }}</b>
              </li>
              <!-- <li @click="handleMenu('2')">
                <b>{{ $t("mypage_payment") }}</b>
              </li>-->
              <li @click="handleMenu('2')">
                <span class="material-icons">loyalty</span>
                <b>{{ $t("mypage_membership") }}</b>
              </li>

              <li @click="handleMenu('3')">
                <span class="material-icons">credit_card</span>
                <b>{{ $t("mypage_charge") }}</b>
              </li>
              <!-- <li @click="handleMenu('4')">
                <span class="material-icons"> assignment </span><b>{{ $t("mypage_payment_management") }}</b>
              </li>-->
              <li @click="handleMenu('5')">
                <span class="material-icons">sms</span>
                <b>{{ $t("mypage_qna_management") }}</b>
              </li>
              <li @click="handleMenu('6')">
                <span class="material-icons">settings</span>
                <b>{{ $t("mypage_changePwd") }}</b>
              </li>
              <!-- <li @click="handleCancel()">구독취소</li> -->
            </ul>
          </article>

          <article class="right subDash card">
            <div class="title">
              <h3 @click="handleDeleteBasket(0)">
                환불 신청
                <!-- <el-tooltip
                  class="item"
                  effect="dark"
                  content="Bottom Center prompts info"
                  placement="bottom"
                >
                  <el-button
                    ><span class="material-icons">
                      help_outline
                    </span></el-button
                  >
                </el-tooltip>-->
              </h3>
            </div>

            <section>
              <div class="refund payment">
                <div class="mainBox noBorder">
                  <div class="form">
                    <fieldset>
                      <div>
                        <p>
                          환불 신청을 계속 하시려면 다음 설문에 답해주시기 바랍니다.
                        </p>
                        <p>
                          남겨주신 정보를 바탕으로 더 나은 서비스를 제공해드리기 위해 노력하겠습니다.
                        </p>
                        <div class="flexB">
                          <p>환불 사유</p>
                          <div>
                            <select class="select" v-model="category">
                              <option value="">환불사유를 선택해주세요.</option>
                              <option value="money">이용 요금이 비싸서</option>
                              <option value="usage">사용빈도가 낮아서</option>
                              <option value="etc">
                                기타(사유를 적어주시면 좋은 참고가 되겠습니다.)
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="flexB reason">
                          <p>기타 다른 사유</p>
                          <div>
                            <textarea name="" id="" cols="30" rows="10" v-model="refund_reason"></textarea>
                          </div>
                        </div>
                        <div class="buttonWrap">
                          <!-- <button v-if="planDetail.status == 'paid'" @click="confirmRefund(planDetail.merchant_uid)"> -->
                          <!-- <router-link to @click.native="handleInfo()"> -->
                          <router-link to="/">
                            <!-- <button
                              v-if="planDetail.status == 'paid'"
                              class="left"
                            > -->
                            <button class="apply">환불신청</button>
                          </router-link>
                          <!-- <router-link to @click.native="handleTab()"> -->
                          <router-link to="/">
                            <button>취소</button>
                          </router-link>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { updateProfileInfo, fetchUserInfo, fetchUserMembership, changePassword } from "@/api/member";
import {
  deleteBasket,
  updateBasket,
  deletePaymentHistory,
  fetchUserPayments,
  refundState,
  cancelSubscribe,
  createCheckout,
  getCheckoutComplete,
  deleteCheckout,
  createCertNumber,
  verifyMobile,
} from "@/api/index";
import { createPost, fetchPostList, fetchPostDetail } from "@/api/post";
import sha256 from "js-sha256";
import { mapState } from "vuex";
import moment from "moment";
import { format } from "@/mixins/format";
import { baseUrl } from "@/config/env";
import { eventBus } from "@/main";
import { verifyPassword } from "@/mixins/verifyPassword";
import QnaReply from "@/components/note/QnaReply";

export default {
  mixins: [format, verifyPassword],
  components: { QnaReply },
  data() {
    return {
      displayBackground: false,
      optionDisplayCenterModal: false,
      refundDisplayCenterModal: false,
      displayBottomModal: false,
      type: "",
      title: "",
      noteVisible: false,
      moment: moment,
      notices: [],
      basket: [],
      file: null,
      baseUrl: baseUrl,
      menuTxt: this.$t("mypage_profile"),
      menuIdx: "1",
      mobileNo: "",
      username: "",
      point: 0,
      addressDetail: "",
      address: "",
      postcode: "",
      password: "",
      logo: "",
      currentPage: "1",
      totalPages: "",
      idx: "",
      buyInfo: [],
      reason: "",
      refundItem: "",
      companyName: "",
      merchant_uid: "",
      serviceType: "",
      subscribeTerm: "",
      nextPayment: "",
      activeName: "first",
      certNumber: "",
      // 충전관련
      chargeAmont: 0,
      totalAmount: 0,
      totalPoint: 0,
      havingPoint: 0,
      plusPoint: 0,
      // 문의사항
      category: "",
      content: "",
      postTitle: "",
      postList: [],
      postTotal: 0,
      postCurrentPage: 1,
      veyfyCodeVisible: false,
      verify: false,
      newPwd: "",
      newPwdConfirm: "",
      qnaData: [],
    };
  },
  created() {
    window.document.title = "마이페이지";
  },
  mounted() {
    eventBus.$on("closeModal", () => {
      this.closeModal();
    });
    this.getProfile();
    this.getUserPayments();
  },
  computed: {
    ...mapState(["userInfo", "isPaid"]),
  },
  methods: {
    requestCertNumber() {
      if (this.mobileNo == "") {
        return alert("핸드폰 번호를 입력해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.veyfyCodeVisible = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          return alert("인증번호가 발송 되었습니다. 핸드폰에서 인증번호를 확인해주세요.");
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyNumber() {
      if (this.certNumber == "") {
        return alert("인증번호를 입력해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.verify = true;
          return alert("정상적으로 인증번호가 확인되었습니다.");
        } else if (res.data.status == 401) {
          this.verify = false;
          return alert(res.data.message);
        }
      });
    },
    handleMenu(index) {
      if (index == "1") {
        this.menuTxt = this.$t("mypage_profile");
        this.getProfile();
      } else if (index == "2") {
        // this.getUserPayments();
        this.menuTxt = this.$t("mypage_membership");
      } else if (index == "3") {
        //this.getMembership();
        this.menuTxt = this.$t("mypage_charge");
      } else if (index == "4") {
        //this.getMembership();
        this.menuTxt = this.$t("mypage_payment_management");
      } else if (index == "5") {
        this.getPostList();
        this.menuTxt = this.$t("mypage_qna_management");
      } else if (index == "6") {
        //this.getMembership();
        this.menuTxt = this.$t("mypage_changePwd");
      }
      this.menuIdx = index;
    },
    getMembership() {
      let data = this.merchant_uid;
      fetchUserMembership(data).then((res) => {
        if (res.status == 200) {
          this.serviceType = res.data.data.serviceType;
          this.subscribeTerm = res.data.data.paid_at;
          this.nextPayment = res.data.data.schedule_at;
        } else {
          alert("올바르지 않은 접근입니다.");
        }
      });
    },
    handleRefund(idx) {
      if (idx == "1") {
        this.reason = this.$t("refund_reason_1");
      } else if (idx == "2") {
        this.reason = this.$t("refund_reason_2");
      }
    },

    viewComponent(view) {
      this.currentComponent = view;
    },
    closeModal() {
      this.currentComponent = null;
      this.optionDisplayCenterModal = false;
      this.refundDisplayCenterModal = false;
      this.getUserPayments();
      this.$store.dispatch("getAccountInfo");
    },
    /**
     * 함수 이름이 뜻하는 것을 명확하게 해야함.
     */
    closeModal2() {
      this.noteVisible = false;
      this.opinionVisible = false;
      document.querySelector("body").style.overflow = "auto";
    },

    handlePaging(page) {
      if (page > this.totalPages) return false;
      else if (page < 1) return false;
      this.currentPage = page;
      this.getUserPayments();
    },
    handleCount(type, item) {
      let count = item.count;
      if (type == 1) {
        count++;
      } else {
        if (item.count == 1) {
          count = 1;
          return alert("상품개수를 1개 이상으로 설정해주세요.");
        } else if (item.count > 0) {
          count--;
        } else count = 1;
      }
      let basket = this.basket.map((unit) => {
        if (unit._id == item._id) {
          unit.count = count;
          return unit;
        } else {
          return unit;
        }
      });
      let data = {
        basket: basket,
      };
      updateBasket(data).then((res) => {
        if (res.data.status == 200) {
          this.getProfile();
        } else {
          return alert(res.data.message);
        }
      });
    },
    getProfile() {
      fetchUserInfo().then((res) => {
        if (res.status == 200) {
          this.merchant_uid = res.data.data.merchant_uid ? res.data.data.merchant_uid : "";
          this.mobileNo = res.data.data.mobileNo;
          this.postcode = res.data.data.postcode;
          this.address = res.data.data.address;
          this.addressDetail = res.data.data.addressDetail;
          this.username = res.data.data.username;
          this.companyName = res.data.data.companyName;
          this.type = res.data.data.type;
          this.point = res.data.data.point;
          this.havingPoint = res.data.data.point; // 변수 정의 중복 이슈
        } else {
          return alert(res.data.message);
        }
      });
    },

    handleOptionModal(item) {
      this.optionDisplayCenterModal = true;
      this.basketElement = item;
      this.idx = item.productId.id;
      // this.$router.push({ name: "productDetail", query: { id: id } });
    },
    handleRefundModal(item) {
      this.refundDisplayCenterModal = true;
      this.refundItem = item;
      this.viewComponent("RefundRequest");
    },
    handleMove(id, type) {
      if (type == "product") {
        this.$router.push({ name: "productDetail", query: { id: id } });
      } else if (type == "userPaymentDetail") {
        this.$router.push({
          name: "userPaymentDetail",
          query: { imp_uid: id },
        });
      }
    },
    handleDeleteBasket(id) {
      deleteBasket(id).then((res) => {
        if (res.status == 200) {
          if (id == "all") {
            this.basket = [];
          } else {
            this.basket = this.basket.filter((item) => {
              if (item._id != id) return item;
            });
          }
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleCancel() {
      let result = confirm("구독취소 하시겠습니까?");
      if (result) {
        cancelSubscribe().then((res) => {
          if (res.data.status == 200) {
            alert(res.data.message);
          } else {
            //production용 소프트웨어에 테스트 문구 사용하지 마세요.
            alert("취소안됨.");
          }
        });
      }
    },
    handleQna(id) {
      this.noteVisible = true;
      document.querySelector("body").style.overflow = "hidden"; // 사용해도 성능상 이슈는 없으나 vue에서 제공하는 문법을 사용하여 프레임워크의 특성과 가독성 살릴것!

      fetchPostDetail(id).then((res) => {
        if (res.data.status == 200) {
          this.qnaData = res.data.data;
        } else {
          return alert(res.data.message);
        }
      });
    },
    getUserPayments() {
      let params = {
        page: this.currentPage,
        // searchWord: this.searchWord
      };
      fetchUserPayments(params).then((res) => {
        /**
         * 에러/예외 처리
         */
        this.buyInfo = res.data.data;
        this.currentPage = res.data.currentPage;
        this.total = res.data.totalPages;
      });
    },
    refundProduct(id) {
      let data = {
        reason: this.reason,
      };
      refundState(id, data).then((res) => {
        if (res.status == 200) {
          this.getUserPayments();
          alert(res.data.message);
        } else {
          alert(res.data.message);
        }
      });
    },
    handleDeleteBuyInfo(id) {
      deletePaymentHistory(id).then((res) => {
        if (res.status == 200) {
          if (id == "all") {
            this.buyInfo = [];
          } else {
            this.buyInfo = this.buyInfo.filter((item) => {
              if (item._id != id) return item;
            });
          }
        } else {
          return alert(res.data.message);
        }
      });
    },

    submit() {
      if (this.userInfo.username == "") {
        return alert("이름을 입력해 주세요.");
      } else if (this.email == "") {
        return alert("이메일을 입력해 주세요.");
      }
      if (this.veyfyCodeVisible && this.verify == false) {
        return alert("핸드폰 인증을 진행해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
        companyName: this.companyName,
      };
      updateProfileInfo(data).then((res) => {
        if (res.data.status == 200) {
          this.veyfyCodeVisible = false;
          this.verify = false;
          alert("회원정보가 수정 되었습니다.");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    updatePwd() {
      if (this.password == "") {
        return alert("이름을 입력해 주세요.");
      } else if (this.newPwd == "") {
        return alert("신규 비밀번호를 입력해 주세요.");
      } else if (this.newPwdConfirm == "") {
        return alert("신규 비밀번호를 주세요.");
      } else if (this.newPwdConfirm != this.newPwd) {
        return alert("신규 비밀번호 확인해 주세요.");
      }
      let result = this.verifyPassword(this.newPwd);
      if (result == false) {
        return;
      }
      let data = {
        password: sha256(this.password),
        newPwd: sha256(this.newPwd),
      };
      changePassword(data).then((res) => {
        if (res.data.status == 200) {
          alert("비밀번호가 수정 되었습니다.");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    calculate(e) {
      this.plusPoint = 0;
      this.totalAmount;
      this.chargeAmont =
        e.target.value.split("")[0] == "0"
          ? e.target.value
              .split("")
              .splice(1)
              .join("")
          : e.target.value;
      if (this.chargeAmont > 999999) {
        this.calculPlus();
      }
      this.calculTotal();
    },
    calculPrice(val) {
      if (this.chargeAmont == "") {
        this.chargeAmont = 0;
      }
      this.plusPoint = 0;
      this.chargeAmont = parseInt(this.chargeAmont) + parseInt(val);
      if (this.chargeAmont > 999999) {
        this.calculPlus();
      }
      this.calculTotal();
    },
    amountCancel() {
      this.chargeAmont = 0;
      this.totalPoint = 0;
    },
    calculPlus() {
      if (this.chargeAmont == 1000000) {
        this.plusPoint = Math.ceil(this.chargeAmont / 100 / 10);
      }
      let num = 0;
      for (let i = 1000000; i < parseInt(this.chargeAmont) + 1; i += 500000) {
        num = Math.ceil(i / 100 / 10);
      }
      this.plusPoint = num;
    },
    calculTotal() {
      this.totalPoint = 0;
      this.totalAmount = 0;
      let charge = this.chargeAmont != "" ? parseInt(this.chargeAmont) / 100 : 0;
      let plus = this.plusPoint != "" ? parseInt(this.plusPoint) : 0;
      let having = this.havingPoint != "" ? parseInt(this.havingPoint) : 0; //negative default 이슈, 타입 오류 이슈
      let pay = this.chargeAmont != "" ? Math.ceil((parseInt(this.chargeAmont) / 100) * 10) + parseInt(this.chargeAmont) : 0;
      if (this.chargeAmont < 100 || "") {
        this.totalPoint == 0;
      } else {
        this.totalPoint = Math.ceil(charge + plus + having);
        this.totalAmount = pay;
      }
    },
    handleCharge() {
      //논리 오류 chargeAmont == 9999
      if (this.chargeAmont < 9999) {
        alert("만원 이상 결제해주시길 바랍니다.");
        return;
      }
      //가독성, 통일성 이슈
      if (!confirm(`${this.comma(this.totalAmount)}원을 결제 하시겠습니까? `)) {
        return;
      }
      let data = {
        pg: "html5_inicis",
        pay_method: "card",
        merchant_uid: "issue_billingkey_" + new Date().getTime(),
        name: "reviewMind + Plan 1",
        amount: this.totalAmount,
        buyer_email: this.userInfo.email,
        buyer_name: this.userInfo.username,
        buyer_tel: this.userInfo.mobileNo,
        buyer_addr: this.userInfo.address,
        buyer_postcode: this.userInfo.zipcode,
      };
      const IMP = window.IMP;
      IMP.init("imp60557482");
      IMP.request_pay(data, (res) => {
        data.imp_uid = res.imp_uid;
        if (res.status == "paid" || res.status == "ready") {
          createCheckout(data).then((res) => {
            if (res) {
              let params = {
                imp_uid: res.imp_uid,
              };
              getCheckoutComplete(params).then((res) => {
                if (res.status === 200) {
                  this.$router.push({ name: "mypage" });
                }
              });
              // .catch((payErr) => {
              //   window.console.error(
              //     "payment Error!",
              //     payErr.response.data.message
              //   );
              // });
            } else {
              deleteCheckout(res.imp_uid).then((response) => {
                if (response.status == 200) {
                  alert(res.error_msg);
                }
              });
            }
          });
        }
      });
    },
    postReset() {
      this.content = "";
      this.postTitle = "";
      this.category = "";
      // this.getQnaList();
    },
    postSubmit() {
      if (this.postTitle == "") {
        return alert("제목을 입력해 주세요");
      } else if (this.content == "") {
        return alert("내용을 입력해 주세요");
      } else if (this.content.length < 10) {
        return alert("문의 내용을 최소 10자 이상 입력해주세요.");
      }
      let data = {
        content: this.content,
        title: this.postTitle,
        category: this.category,
      };
      createPost(data).then((res) => {
        if (res.data.status == 200) {
          this.activeName = "second";
          this.postReset();
          this.getPostList();
          return alert("질문 등록이 완료 되었습니다.");
        } else {
          return alert(res.data.message);
        }
      });
    },
    getPostList() {
      let params = {
        page: this.currentPage,
      };
      fetchPostList(params).then((res) => {
        if (res.data.status == 200) {
          this.postList = res.data.postList;
          this.postTotal = res.data.total;
          this.postCurrentPage = res.data.page;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleCurrentChange_post(val) {
      // 함수명 가독성 이슈
      this.postCurrentPage = val;
      this.getPostList();
    },
  },
};
</script>
